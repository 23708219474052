import BannerBG from "@components/Banner/BannerBG"
// import DeveloperInfo from "@components/DeveloperInfo/DeveloperInfo"
import Layout from "@components/Layout/Layout"
import ResultsBreadcrumb from "@components/PropertyBreadcrumb/ResultsBreadcrumb"
import InfoMapSort from "@components/SearchResults/InfoMapSort/InfoMapSort"
import PropertyList from "@components/SearchResults/PropertyList/PropertyList"
import SearchFilters from "@components/SearchResults/SearchFilters/SearchFilters"
import SearchPagination from "@components/SearchResults/SearchPagination/SearchPagination"
import useInitPropertyData from "@components/SearchResults/hooks/useInitPropertyData"
import useUrlData from "@components/SearchResults/hooks/useUrlData"
import { usePropertyStore } from "@components/SearchResults/store/propertyStore"
import PropertyCopy from "@components/TextCopy/PropertyCopy"
import Seo from "@components/seo"
import { createResultsCanonicalUrl, getAreaPath, getH1Text, getSeoDesc, parseResultsUrl } from "@lib/searchUtils"
import { offplanResultsURL } from "@lib/urls"
import loadable from "@loadable/component"
import "@styles/search-results.scss"
import clsx from "clsx"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import useHasScrolled from "@hooks/useHasScrolled"
import RSeo from "@components/resultsseo"
import { useGlobalStore } from "@stores/globalStore"
// import ProjectSpotlight from "@components/PropertyAreaguide/ProjectSpotlight"
// import UsefulLInks from "@components/PopularSearchModule/UsefulLInks"
// import PropertyNegoCardSR from "@components/PropertyNegoCard/PropertyNegoCardSR"
// import PropertyAreaguideSR from "@components/PropertyAreaguide/PropertyAreaguideSR"
// import Cardsidebar from "@components/PopularSearchModule/Cardsidebar"
import useTeams from "@hooks/dataHooks/useTeams"
import useAreaguides from "@hooks/dataHooks/useAreaguides"
// import ResidentialPopularSearch from "@components/PopularSearchModule/PopularSearchModule"
import DTeamsSlider from "@components/TeamsSlider/DTeamsSlider"
import logo from "@images/logo.svg"
import { graphql, useStaticQuery } from "gatsby"
import useBrandInfo from "@hooks/dataHooks/useBrandInfo"
import { gtagReportConversion } from "@lib/utils"
import useUrlDataSeo from "@components/SearchResults/hooks/useUrlDataSeo"
const ContactFomModule = loadable(() => import("@components/ContactFomModule/ContactFomModule"))
const DeveloperInfo = loadable(() =>
  import("@components/DeveloperInfo/DeveloperInfo")
)
const PropertyMap = loadable(() =>
  import("@components/SearchResults/map/RenderMap")
)
const Brightcall = loadable(() => 
  import("@components/Brightcall/Brightcall")
)
const { useLocation } = require("@reach/router")
const SearchResults = ({ location }) => {
  const { phone } = useBrandInfo()
  const [brignt, setBright] = useState(false)
  const propertyURL = typeof window !== "undefined" ? window.location.pathname : ""
  // const offlistView = usePropertyStore((state) => state.offlistView)
  const scrolled = useHasScrolled()
  const [isLoad, setLoad] = useState(false)
  // useEffect(() => {
  //   setLoad(true)
  // })
  const pageUrlData = parseResultsUrl(location)
  let h1Text;
  if(pageUrlData){
    h1Text = getH1Text(pageUrlData)
  }
  const path =
    location.pathname ||
    (typeof window !== "undefined" && window.location.pathname)

  useInitPropertyData()
  const showMap = usePropertyStore((state) => state.showMap)
  const searchResult = usePropertyStore((state) => state.searchResult)
  // const {minBedroom, maxBedroom} = usePropertyStore()
  const pType = usePropertyStore((state) => state.pType)
  const pDepartment = usePropertyStore((state) => state.pDepartment)
  const selectedAreas = usePropertyStore((state) => state.selectedAreas)
  const {developer} = usePropertyStore(
    (state) => ({
      developer: state.developer
    })
  )
  const buildingType = usePropertyStore((state) => state.buildingType)
  const { nbHits, nbPages, page } = searchResult

  useEffect(() => {
    if (nbHits > 0) {
      // enable other components after 1 second
      setLoad(true)
    }
  }, [nbHits])

  // const dynamicData = {
  //   department: pDepartment,
  //   area: selectedAreas?.length > 0 ? getAreaPath(selectedAreas)?.replace("in-", "")?.replace("/", "") : "dubai",
  //   searchType: pType,
  //   bedroom: minBedroom?.value,
  //   propertyType: buildingType?.value,
  // }
  // Redirect to 404 if the URL is not valid
  const matchURLs = [
    "type-",
    "above-",
    "below-",
    "between-",
    "-bedrooms",
    "status-",
    "developed-",
    "in-",
    "page-",
    "sortby-",
    "with-features-"
  ]

  const path2 = path.split("/")[2] || ""

  if (
    !matchURLs?.some((matchURL) => path2.includes(matchURL)) &&
    path !== offplanResultsURL
  ) {
    typeof window !== "undefined" && navigate("/new-projects/", { replace: true })
  }

  const showCopy =
    typeof window !== "undefined" &&
    (path === `${offplanResultsURL}` || path?.includes("type-"))
    // const { spotlight} = useGlobalStore()
    const allTeams = useTeams()
    // const allAreas = useAreaguides()
    // Function to filter allTeams by community
    const filterByCommunity = (teams, communities) => {
      return teams.filter(team => {
        // Check if the community field in team contains a matching slug or name
        return communities.some(community => {
          return team?.community?.strapi_json_value?.some(value =>
            value.includes(community.name)
          );
        });
      });
    };
    // const filterByAreaguide = (areas, communities) => {
    //   return areas.filter(area => {
    //     // Check if the community field in team contains a matching slug or name
    //     return communities.some(community => {
    //       return area?.title.includes(community.name)
    //     });
    //   });
    // };
    const [filteredData, setFilteredData] = useState([])
    // const [filteredArea, setFilteredArea] = useState([])
    // Applying the filter
    // let whatsappURL = ""
  
    useEffect(() => {
      let filteredDataTemp = [];
      let filteredAreaTemp = []
      if (selectedAreas && selectedAreas.length > 0) {
        filteredDataTemp = filterByCommunity(allTeams, selectedAreas);
        // filteredAreaTemp = filterByAreaguide(allAreas, selectedAreas)
      }
  
      // If no teams were found, check for "Laura Victoria Adams"
      if (filteredDataTemp.length === 0) {
        filteredDataTemp = allTeams.filter(team => team?.name === "Laura Victoria Adams");
      }
      
      setFilteredData(filteredDataTemp);
      // setFilteredArea(filteredAreaTemp)
  
    }, [path, selectedAreas]);
  return (
    <Layout
      pageData={{
        page_class: clsx(
          "search-results-page offplan-results-page",
          nbHits === 0 && "no-properties-page",
          scrolled && "search-fit"
        ),
        layout: "search-results offplan-results",
      }}
    >
      {showMap ? (
        <div className="search-map-wrapper">
          <PropertyMap />
        </div>
      ) : (
        <div className="offplan-results-wrap  min-vh-100">
         {isLoad && 
          <>
          
          <BannerBG />
          <SearchFilters />
          {/* {nbHits !== undefined &&
          <div className="dd-div"> */}
         <ResultsBreadcrumb path={path} search_type={pType} isLoad={isLoad} h1Text={h1Text ? h1Text : ""} department={"new_developments"} />
          <DeveloperInfo />
          <div className={clsx("search-results-section offplan-results-section")}>
          <InfoMapSort h1Text={h1Text} isLoad={isLoad} />
          <PropertyList selectedAreas={selectedAreas} isNot  />
            {nbPages > page &&
            <SearchPagination />}
          {/* <div className={clsx(
          "new-layout-with-sidebar container",
          "list-k",
          (nbHits === "0") || (nbPages === 0 &&  page === 0) && "no-result"
        )}>
          <div>
            <PropertyList selectedAreas={selectedAreas} isNot  />
            {nbPages > page &&
            <SearchPagination />}
             </div>{(nbHits !== 0) &&
            <div className="side-bar-listing-page">
              <div className="sticky-container">
            <ProjectSpotlight content_cta={spotlight} />
            <ResidentialPopularSearch dynamicPageData={dynamicData}  />
            <UsefulLInks />
            <PropertyNegoCardSR team_data={filteredData} />
            <PropertyAreaguideSR area={filteredArea?.length > 0 && filteredArea[0]} />
            <Cardsidebar pType={"projects"} />
            </div></div>}

            </div> */}
          </div>

          {/* </div> 
          // : 
          // <div className="dd-loader"></div>
          } */}
          {nbHits > 0 && developer?.label !== "All Developers" &&
          <DTeamsSlider title={`Meet Our Top Experts for ${developer?.label}`} description={`Are you searching for projects by ${developer?.label}? Our specialists are here to guide you in finding the perfect home or investment opportunity.`} developer={developer} />}

          </>
          }
        </div>
      )}
      {nbHits !== undefined &&<PropertyCopy
        pType={pType}
        buildingType={buildingType}
        pDepartment={pDepartment}
        selectedAreas={selectedAreas}
        developer={developer}
      />}
      {nbHits > 0 && developer?.label !== "All Developers" &&
      <ContactFomModule
          module={{
            heading: "Speak with our Real Estate specialists today",
            title:
              "Get in touch for tailored guidance from our expert team. We’re committed to assisting you through each phase of your journey.",
          }}
        />}
         <a
                                      href="javascript:void(0);"
                                      className="bright-cta"
                                      onClick={() => 
                                        {
                                          gtagReportConversion(`tel:${phone}`)
                                          setBright(true)
                                        }
                                         }
                                    >
                                        <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M1.875 5.625C1.875 12.5286 7.47144 18.125 14.375 18.125H16.25C17.2855 18.125 18.125 17.2855 18.125 16.25V15.107C18.125 14.6768 17.8322 14.3018 17.4149 14.1975L13.7289 13.276C13.363 13.1845 12.9778 13.3212 12.7515 13.623L11.943 14.701C11.7083 15.0139 11.3025 15.1522 10.9353 15.0177C8.17949 14.0082 5.9918 11.8205 4.9823 9.06472C4.84778 8.69749 4.98613 8.29165 5.299 8.057L6.37702 7.24849C6.67878 7.02217 6.81551 6.63704 6.72403 6.27111L5.80253 2.58512C5.69819 2.16778 5.32321 1.875 4.89302 1.875H3.75C2.71447 1.875 1.875 2.71447 1.875 3.75V5.625Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        </div>
                                        <div className="text">
                                            <div className="tit">Get a call within 55 seconds</div>
                                            <div className="cta"><span>Click here</span> to get started</div>
                                        </div>
                                        </a>
                        {brignt && <Brightcall setBright={setBright} propertyURL={propertyURL}/>}
    </Layout>
  )
}

export const Head = (props) => {
  const data = useStaticQuery(graphql`
    query {
           allProjects(filter: {publish: {eq: true}}) {
          totalCount
        }
    }
  `)
  let { pathname } = useLocation()
  const pageUrlData = useUrlData()

  const h1Text = getH1Text(pageUrlData)
  const seoDesc = getSeoDesc(pageUrlData)
  const [customCanonical, setCustomCanonical] = useState(process.env.GATSBY_SITE_URL + pathname)
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const listingpage = (typeof window !== "undefined" && window.location.href)
  var ldJson = {
    "@context": "https://schema.org/",
    "@id": "SearchResultsPage",
    "@type": [
      "SearchResultsPage",
      "Product"
    ],
    "accessModeSufficient": {
      "@type": "ItemList",
      "name": h1Text,
      "itemListOrder": "https://schema.org/ItemListOrderDescending",
      "numberOfItems": data?.allProjects?.totalCount,
      "itemListElement": []
    },
    "brand": {
      "@type": "Organization",
      "name": "Provident Estate"
    },
    "breadcrumb": {
      "@id": pageurl+"#breadcrumb",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "name": "Provident Estate",
          "item": process.env.GATSBY_SITE_URL,
          "position": 1
        },
        {
          "@type": "ListItem",
          "name": h1Text,
          "item": listingpage,
          "position": 2
        }
      ]
    },
    "contentLocation": {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Dubai"
      }
    },
    "description": seoDesc,
    "image": "https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/x.dev/1773x/888_4af09da9b9.webp",
    "name": h1Text,
    "offers": {
      "@type": "AggregateOffer",
      "highPrice": "999999999",
      "lowPrice": "100000",
      "offerCount": data?.allProjects?.totalCount,
      "priceCurrency": "AED"
    },
    "sameAs": "https://en.wikipedia.org/wiki/Search_engine_results_page",
    "speakable": {
      "@type": "SpeakableSpecification"
    }
   

  }

  var ldjson1 = {
    "@context": "http://schema.org",
    "@id": "Organization",
    "@type": [
      "Organization",
      "Corporation"
    ],
    "logo": logo,
    "url": process.env.GATSBY_SITE_URL,
    "brand": {
      "@type": "Brand",
      "name": "Provident Estate"
    },
    "name": "Provident Estate",
    "address": "#1802, 18th Floor, Marina Plaza, Dubai Marina, Dubai, UAE",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+971505390249",
      "contactType": "customer service",
      "areaServed": "ae",
      "availableLanguage": [
        "en",
        "ar"
      ]
    },
    "sameAs": [
      "https://www.facebook.com/providentestate",
      "https://www.instagram.com/providentestate",
      "https://www.linkedin.com/company/providentestate",
      "https://www.youtube.com/@Providentestate",
      "https://twitter.com/providentagents"
    ]
  }
  useEffect(() => {
    const fetchCanonicalUrl = async () => {
      const canonicalUrl = await createResultsCanonicalUrl({ pageUrlData })
      setCustomCanonical(process.env.GATSBY_SITE_URL+canonicalUrl)
    }

    fetchCanonicalUrl()
  }, [pathname, h1Text])
  if (!customCanonical) {
    // You can return a loading state or default canonical URL here if needed
    return null
  }
  // let customCanonical = createResultsCanonicalUrl({pageUrlData})
  return <>
  <link crossorigin href="https://d4vban0ooq-2.algolianet.com" rel="preconnect" /><RSeo 
  customCanonical={customCanonical || process.env.GATSBY_SITE_URL + pathname}
  title={h1Text} description={seoDesc} isproperty ldJson={ldJson} ldjson1={ldjson1} /></>
}

export default SearchResults